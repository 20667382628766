.sticky {
    background: #43434380 !important;
    transition: all 0.3s ease-out 0s !important;
    box-shadow: 0px 2px 2px 0px var(--highlight-color);
    backdrop-filter: blur(16px) !important;
}

.navbar {
    position: fixed !important;
    transition: all 0.2s ease-out 0s !important;
    padding: 0.2rem 2rem !important;
    font-size: 1.2rem !important;
}

.logo {
    height: 1.2em !important;
    width: 2.4em !important;
}

.navbar-toggler {
    position: relative !important;
    background-color: transparent !important;
    border-color: transparent !important;

    & span {
        display: block !important;
        background-color: var(--highlight-color);
        height: 4px !important;
        width: 28px !important;
        margin: 4px 0 !important;
        transform: rotate(0deg) !important;
        left: 0 !important;
        opacity: 1 !important;

        &:nth-child(1),
        &:nth-child(3) {
            transition: transform 0.35s ease-in-out !important;
        }
    }

    &:focus,
    &:active {
        outline: 0 !important;
        box-shadow: none !important;
    }

    &:not(.collapsed) {
        & span:nth-child(1) {
            position: absolute !important;
            left: 12px !important;
            top: 12px !important;
            transform: rotate(135deg) !important;
            opacity: 0.8 !important;
        }

        & span:nth-child(2) {
            height: 12px !important;
            visibility: hidden !important;
            background-color: transparent !important;
        }

        & span:nth-child(3) {
            position: absolute !important;
            left: 12px !important;
            top: 12px !important;
            transform: rotate(-135deg) !important;
            opacity: 0.8 !important;
        }
    }
}

@media (max-width: 800px) {
    .navbar {
        padding: 1rem 2rem !important;
        font-size: 1.2rem !important;
        background-color: var(--characteristic-color);
    }

    .navbar-nav .nav-item a::after {
        display: none !important;
    }
}

.navbar-nav {
    .nav-link {
        color: white !important;
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }

    .nav-item {
        position: relative;
        margin-left: 20px;

        a {
            font-weight: 400;
            transition: all 0.3s ease-out 0s;
            position: relative;
            z-index: 1;

            &::after {
                content: "";
                position: relative;
                display: block;
                height: 5px;
                width: 0;
                border-radius: 16px;
                background: var(--highlight-color);
                bottom: 1px;
                left: 0;
                z-index: -1;
                transition: all 0.3s ease-out 0s;
            }

            &:hover::after {
                width: 100%;
            }
        }
    }
}