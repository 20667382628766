.wave {
    animation-name: wave-animation;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    transform-origin: 70% 70%;
    display: inline-block;
}

@keyframes wave-animation {

    0%,
    60%,
    100% {
        transform: rotate(0deg);
    }

    10%,
    30% {
        transform: rotate(16deg);
    }

    20% {
        transform: rotate(-8deg);
    }

    40% {
        transform: rotate(-4deg);
    }

    50% {
        transform: rotate(12deg);
    }
}

.home-section {
    padding: 28px 0 !important;

    .home-content {
        padding: 8rem 0 2rem !important;
        color: white;
        text-align: left;
    }

    .home-header {
        padding-top: 80px !important;

        .heading {
            font-size: 2.4em !important;
            padding-left: 52px !important;
        }

        .heading-name {
            font-size: 2.4em !important;
            padding-left: 52px !important;

            .main-name {
                color: var(--highlight-color)
            }
        }

        .Typewriter__wrapper {
            color: lighten(yellow, 20%);
            font-size: 2em !important;
            font-weight: 600 !important;
        }

        .Typewriter__cursor {
            color: lighten(yellow, 20%);
            font-size: 2.4em !important;
        }
    }
}

.home-about-section {
    position: relative;
    padding: 80px 0 !important;

    .home-about-description {
        color: white !important;
        padding: 100px 0 20px !important;
        text-align: center;
    }

    .home-about-body {
        padding-top: 52px;
        font-size: 1.2em !important;
        text-align: left;
    }

    .my-avatar {
        padding-top: 8em !important;
        justify-content: center !important;
    }
}

.home-about-social {
    color: white !important;
    padding-top: 24px;
    text-align: center !important;

    .home-about-social-links {
        position: relative !important;
        justify-content: center !important;
        padding-top: 16px !important;
        display: inline-block !important;
        padding-inline-start: 0 !important;


        .icon-colour {
            color: black !important;

            &.home-social-icons {
                position: relative !important;
                display: inline-block !important;
                width: 40px !important;
                height: 40px !important;
                text-align: center !important;
                font-size: 1.2em !important;
                line-height: 2em !important;
                background: white !important;
                border-radius: 50% !important;
                transition: 0.4s !important;

                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    background: var(--characteristic-color);
                    transition: 0.4s;
                    transform: scale(0.9);
                    z-index: -1;
                }

                &:hover::before {
                    transform: scale(1.1);
                    box-shadow: 0 0 16px lighten(yellow, 20%);
                }
            }
        }
    }
}


@media (max-width: 800px) {
    .Typewriter__wrapper {
        position: absolute !important;
        font-size: 1.2em !important;
        font-weight: 500 !important;
    }

    .Typewriter__cursor {
        display: none !important;
    }

    .my-avatar {
        padding: 2em 0 !important;
    }
}