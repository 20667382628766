:root {
    --characteristic-color: #aaabbb !important;
    --highlight-color: yellow !important;
}

.highlight-text {
    color: var(--highlight-color);
}

#tsparticles {
    position: fixed !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    width: 100%;
    height: 100%;
}

.social-icons {
    display: inline-block !important;
    padding: 0 16px;
}

/* --------- */
/* Preloader */
/* --------- */

#preloader {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999999;

    background-color: black;
    background-image: url('../img/pre.svg');
    background-repeat: no-repeat;
    background-position: center;

    &-none {
        opacity: 0;
    }
}

#no-scroll {
    overflow: hidden;
    height: 100vh;
}

/* --------- */
/* Scrollbar */
/* --------- */

::-webkit-scrollbar {
    width: 8px;

    &-track {
        background: black;
    }

    &-thumb {
        background: var(--characteristic-color);
        border-radius: 12px;

        &:hover {
            background: var(--highlight-color);
            border-radius: 12px;
        }
    }
}

/* --------- */
/*   Footer  */
/* --------- */
.footer {
    background-color: #434343;
    bottom: 0 !important;
    padding-top: 10px !important;
    padding-bottom: 8px !important;

    &-copywright {
        text-align: center !important;
    }

    &-body {
        z-index: 100;
        text-align: center !important;
    }

    & h3 {
        font-size: 1em;
        color: white !important;
        margin-top: 0.4em !important;
        margin-bottom: 0.4em !important;
    }

    &-icons {
        margin-top: 0.4em !important;
        margin-bottom: 0.4em !important;
        padding: 0 !important;
    }

    a {
        text-decoration: none;
        color: var(--highlight-color);
    }
}

@media (max-width: 800px) {
    .footer-copywright {
        text-align: center !important;
    }

    .footer-body {
        text-align: center !important;
    }
}