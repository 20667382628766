.about {
    &-section {
        position: relative !important;
        padding-top: 152px !important;
        padding-bottom: 32px !important;
        background-image: var(--section-background-color) !important;
        color: white !important;

        .quote-card-view {
            border: none !important;
            color: white !important;
            background-color: transparent !important;
        }

        .tech-icons {
            font-size: 4.4em !important;
            margin: 16px !important;
            padding: 12px !important;
            opacity: 0.92 !important;
            border: 1.8px solid lighten(yellow, 40%);
            vertical-align: middle !important;
            text-align: center !important;
            border-radius: 5px !important;
            display: table !important;
            box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137) !important;
            overflow: hidden !important;
            transition: all 0.4s ease 0s !important;

            &:hover {
                transform: scale(1.05) !important;
                overflow: hidden !important;
                border: 2.2px solid lighten(yellow, 20%);
            }

            &-images {
                padding: 20px !important;
                line-height: 1.6 !important;
            }

        }
    }

    &-activity {
        list-style: none !important;
        text-align: left !important;
        padding-left: 1px !important;
    }
}

@media (max-width: 800px) {
    .tech-icons {
        margin: 12px !important;
    }

    .about-img {
        padding-top: 0 !important;
    }
}